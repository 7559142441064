<template>
	<div class="form-group">
		<div class="data-group">
			<div class="title">{{ $t('salary.contracts.contract') }}</div>
		</div>
		<div class="form">
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                <div class="label-header">
                    <label class="label">{{ $t('general.name') }}</label>
                </div>
                <div class="input-box">
                    <div class="icon-left"><img v-if="data.avatar" :src="data.avatar" alt=""> <icon-user v-else /> </div>
                        <input disabled placeholder="0" type="text" class="input-text" v-model="data.name">
                </div>
            </div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                <div class="label-header">
                    <label class="label">{{ $t('salary.personal_data.cnp') }}</label>
                </div>
                <div class="input-box">
                    <div class="icon-left"><icon-cnp /></div>
                    <input disabled placeholder="0" type="text" class="input-text" v-model="data.cnp">
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import IconUser from '../../../../Icons/User'
import IconCnp from '../../../../Icons/Cnp'
	export default {
		components: {
            IconCnp,
            IconUser
		},
		props: {
            data: Object,
		},
		data() {
			return {}
		},
	}
</script>