<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$route.query.from_salary ? $router.push({ name: 'other-leaves' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'vacations'} })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title" v-if="$route.query.view == 'true'">{{ $t('salary.vacation.title_view') }}</h1>
				<h1 class="title" v-else>{{ $route.params.id ? $t('salary.vacation.title_edit') : $t('salary.vacation.title_new')}}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$route.query.from_salary ? $router.push({ name: 'other-leaves' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'vacations'} })"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<show-contract v-if="userData" :data="userData" />
			<choose-contract v-if="!$route.params.contract_id && !$route.params.contract_id" :contract="vacation" :validator="$v.vacation"/>
			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('salary.vacation.title') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.type_vacation') }}*</label>
							<div v-if="$v.vacation.selectedVacationType.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.vacation.selectedVacationType.$error}">
							<div class="icon-left"><icon-vacation /></div>
							<div class="icon-right" v-if="vacation.selectedVacationType != '' && !$route.query.view" @click.stop="vacation.selectedVacationType = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								:disabled="$route.query.view == 'true'"
								v-model="vacation.selectedVacationType"
								v-bind:class="{populate: vacation.selectedVacationType != ''}"
								class="select-tags-tbf"
								:options="vacationOptionsType"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.vacation.type_vacation-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.type_calcul') }}*</label>
							<div v-if="$v.vacation.selectedVacationBase.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.vacation.selectedVacationBase.$error}">
							<div class="icon-left"><icon-calculator /></div>
							<div class="icon-right" v-if="vacation.selectedVacationBase != '' && !$route.query.view" @click.stop="vacation.selectedVacationBase = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								:disabled="$route.query.view == 'true'"
								v-model="vacation.selectedVacationBase"
								v-bind:class="{populate: vacation.selectedVacationBase != ''}"
								class="select-tags-tbf"
								:options="vacationOptionsBases"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="label"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.vacation.type_calcul-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.start_date') }}*</label>
							<div v-if="$v.vacation.start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div v-if="$route.query.view == 'true'" class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model="moment(vacation.start_date.selectedDate).format('DD MMM YYYY')">
						</div>
						<div v-else class="input-box" v-bind:class="{has_error: $v.vacation.start_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(vacation.start_date).length" @click.stop="vacation.start_date = {}, vacation.calendar_days = '', vacation.working_days = ''"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="vacation.start_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
								@input="calculateWorkingDays(), changeEndDate()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(vacation.start_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.vacation.start_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.final_date') }}*</label>
							<div v-if="$v.vacation.final_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div v-if="$route.query.view == 'true'" class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model="moment(vacation.final_date.selectedDate).format('DD MMM YYYY')">
						</div>
						<div v-else class="input-box" v-bind:class="{has_error: $v.vacation.final_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right"  v-if="Object.keys(vacation.final_date).length" @click.stop="vacation.final_date = {}, vacation.calendar_days = '', vacation.working_days = ''"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="vacation.final_date"
								class="calendar-tbf"
								:configs="calendarConfigsEndData"
								@input="calculateWorkingDays"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(vacation.final_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.vacation.final_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div v-if="vacation.calendar_days >= 0 && vacation.calendar_days != ''" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.calendar_days') }}*</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model="vacation.calendar_days">
						</div>
					</div>
					<div v-if="vacation.working_days >= 0 && vacation.working_days != ''" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.working_days') }}*</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model="vacation.working_days">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.observations') }}</label>
						</div>
						<div class="input-box bg-white">
							<textarea :disabled="$route.query.view == 'true'" rows="3" :placeholder="$t('salary.vacation.observations-ph')" v-model="vacation.observations"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$route.query.from_salary ? $router.push({ name: 'other-leaves' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'vacations'} })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else-if="!$route.query.view">
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="$router.push({ name: 'vacations-edit', params: {id: $route.params.id, contract_id: $route.params.contract_id} })" v-else-if="$route.query.view == 'true'">
				<div class="loader"></div>
				<span class="text">{{ $t('general.edit') }}</span>
			</button>
		</div>
	</div>
	<loader-vacation-create v-else/>
</template>

<script>
	import ShowContract from  './ShowContract'
	import ChooseContract from  './ChooseContract'
	import IconVacation from '../../../../Icons/Vacation'
	import IconCalculator from '../../../../Icons/Calculator'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'
	import IconArrowBack from '../../../../Icons/ArrowLong'
	import LoaderVacationCreate from '../../../../PagesLoaders/UsersCreate'
    import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			ShowContract,
			ChooseContract,
			IconVacation,
			IconCalculator,
			IconCalendar,
			IconClose,
			IconArrowBack,
			LoaderVacationCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				error_message: '',
				vacationOptionsBases: [],
				vacationOptionsType: [],
                vacation: {
					contractSelected: '',
					selectedVacationType: '',
                    selectedVacationBase: '',
					start_date: {},
					final_date: {},
					calendar_days: '',
					working_days: '',
					observations: ''
                }
			}
		},
        validations: {
            vacation: {
				contractSelected: {
                    required: requiredIf(function () {
                        return !this.$route.params.id && !this.$route.params.contract_id
                    })
			    },
                selectedVacationType: { required },
				selectedVacationBase: { required },
				start_date: { required },
				final_date: { required }
            }
        },
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            await this.getOptionsVacation();

			if(this.$route.params.id){
				await this.getVacationData()
			}
		},
        methods: {
            async getOptionsVacation() {
                await axios.get('/annual-leave-form-noms').then(({data}) => {
					this.vacationOptionsBases = data.data.annual_leave_bases
					this.vacationOptionsType = data.data.annual_leave_types
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },
			async getVacationData() {
				await axios.get(`/annual-leaves/${this.$route.params.id}`).then(async ({data}) => {
					this.vacation.selectedVacationType = this.vacationOptionsType.find(el => el.id == data.data.annual_leave_type_id)
					this.vacation.selectedVacationBase = this.vacationOptionsBases.find(el => el.id == data.data.annual_leave_base_id)
                    if(data.data.start_date){
						this.vacation.start_date = {
							selectedDate: data.data.start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.end_date){
						this.vacation.final_date = {
							selectedDate: data.data.end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
					this.vacation.calendar_days = data.data.calendar_days
					this.vacation.working_days = data.data.working_days
					this.vacation.observations = data.data.observations
                }).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

			changeEndDate() {
				if(this.vacation.start_date.selectedDate > this.vacation.final_date.selectedDate) {
					this.vacation.final_date = {}
				}
				this.calendarConfigsEndData.limits.min = this.vacation.start_date.selectedDate;
			},

			async calculateWorkingDays() {
				if(Object.keys(this.vacation.start_date).length && Object.keys(this.vacation.final_date).length){
					var start = moment(this.vacation.start_date.selectedDate, "YYYY-MM-DD");
					var end = moment(this.vacation.final_date.selectedDate, "YYYY-MM-DD");
					this.vacation.calendar_days = moment.duration(end.diff(start)).asDays()+1

					await axios.get('/working-days-service', {params: {start_date: this.vacation.start_date.selectedDate , end_date: this.vacation.final_date.selectedDate}}).then(({data}) => {
						this.vacation.working_days = data.data.days
					})
				}
			},
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

					objData.start_date = Object.keys(this.vacation.start_date).length ? this.vacation.start_date.selectedDate : ''
					objData.end_date = Object.keys(this.vacation.final_date).length ? this.vacation.final_date.selectedDate : ''
					objData.contract_id = this.$route.params.contract_id ? this.$route.params.contract_id : this.vacation.contractSelected.id
					objData.annual_leave_type_id = this.vacation.selectedVacationType ? this.vacation.selectedVacationType.id : ''
					objData.annual_leave_base_id = this.vacation.selectedVacationBase ? this.vacation.selectedVacationBase.id : ''
					objData.calendar_days = this.vacation.calendar_days
					objData.working_days = this.vacation.working_days
					objData.observations = this.vacation.observations

					if(type == 'create'){
						this.createVacation(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateVacation(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createVacation(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/annual-leaves/store', objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'other-leaves' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id ? this.$route.params.contract_id : this.vacation.contractSelected.id}, query: {tab: 'vacations'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},

			updateVacation(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.patch(`/annual-leaves/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'other-leaves' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'vacations'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			}
        }
	}
</script>